import { container, title } from "assets/jss/material-kit-react.js";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  video: {
    // backgroundColor: 'red',
    // objectFit: "cover",
    // width: "100vw",
    // height: "100vh",
    // position: 'fixed',
    // top: 0,
    // left: 0

    position: "fixed",
    right: 0,
    bottom: 0,
    minWidth: "100%",
    minHeight: "100%",
  },
  /* Add some content at the bottom of the video/page */
content: {
  position: "fixed",
  bottom: 0,
  background: "rgba(0, 0, 0, 0.5)",
  color: "#f1f1f1",
  width: "100%",
  padding: 20
},

/* Style the button used to pause/play the video */
myBtn: {
  width: "200px",
  fontSize: 18,
  padding: 10,
  border: "none",
  background: "#000",
  color: "#fff",
  cursor: "pointer"
},

// myBtn:hover {
//   background: #ddd;
//   color: black;
// }
  
};

export default landingPageStyle;
