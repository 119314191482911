import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Schedule from "@material-ui/icons/Schedule";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Button from "components/CustomButtons/Button.js";
import Grid from "@material-ui/core/Grid";

import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionPills() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="navigation-pills">
          {/* <div className={classes.title}>
            <h3>Navigation Pills</h3>
          </div>
          <div className={classes.title}>
            <h3>
              <small>With Icons</small>
            </h3>
          </div> */}
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <GridItem xs={0} md={2} />
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>Upcoming Events</h2>
              <hr />
              <span>
                <p>
                  <div className={classes.typo} style={{ paddingBottom: 15 }}>
                  <Grid container justify="space-between" direction="row" alignItems="flex-start">

                    <h6>Sat. March 6th 2021 @ <em>Cabin Livestream, Blugold Living Room </em> <br />(Davies Center, 2nd floor), Eau Claire, WI
                      <br />
                      <b>6-7:30pm</b>
                    </h6>
                      <Button color="primary" size="sm" align="right"
                        href="https://www.facebook.com/visiblesoundshow/events/?ref=page_internal" target="_blank">
                        Link
                      </Button>
                    </Grid>
                  </div>
                </p>
              </span>
            </GridItem>
            <GridItem xs={0} md={2} />
            <GridItem xs={0} md={2} />
            <GridItem xs={12} sm={12} md={8}>
              <span>
                <p>
                  <div className={classes.typo} style={{ paddingBottom: 15 }}>
                  <Grid container justify="space-between" direction="row" alignItems="flex-start">

                    <h6>Sat. May 22nd 2021 @ <em>SpringFest, Northern WI State Fairground </em> <br />Chippewa Falls, WI
                      <br />
                      {/* <b>6-7:30pm</b> */}
                    </h6>
                      {/* <Button color="primary" size="sm" align="right"
                        href="https://www.facebook.com/visiblesoundshow/events/?ref=page_internal" target="_blank" disabled>
                        Live
                      </Button> */}
                    </Grid>
                  </div>
                </p>
              </span>
            </GridItem>
            <GridItem xs={0} md={2} />
            <GridItem xs={0} md={2} />
            <GridItem xs={12} sm={12} md={8}>
              <span>
                <p>
                  <div className={classes.typo} style={{ paddingBottom: 15 }}>
                    
                    <Grid container justify="space-between" direction="row" alignItems="flex-start">
                      <h6>Sept 3-5, 2021 @ <em>Portage County Fair, Portage County Fairgrounds, Rosholt Fair Park</em> <br />Rosholt, WI
                        <br />
                        <b>TBA</b>
                      </h6>
                      {/* <Button color="primary" size="sm"
                        href="https://www.facebook.com/visiblesoundshow/events/?ref=page_internal" target="_blank" disabled>
                        TBA
                      </Button> */}
                    </Grid>

                  </div>
                </p>
              </span>
            </GridItem>
            <GridItem xs={0} md={2} />
          </Grid>
          {/* <GridContainer>
            <GridItem xs={0} md={2} />
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>Upcoming Events</h2>
              <hr />
              <span>
                <p>
                  <div className={classes.typo} style={{ paddingBottom: 15 }}>
                    <h6 style={{ marginBottom: 0 }}>Sat. March 6th 2021 @ <em>Cabin Livestream, Blugold Living Room </em> <br />(Davies Center, 2nd floor), Eau Claire, WI
                      <br />
                      <b>6-7:30pm</b>
                    </h6>
                    <Button color="primary" size="sm" align="right" style={{ position: 'absolute', right: 15, top: 140 }}
                      href="https://www.facebook.com/visiblesoundshow/events/?ref=page_internal" target="_blank">
                      Link
                    </Button>
                  </div>
                </p>
                <p>
                  <div className={classes.typo} style={{ paddingBottom: 15 }}>
                    <h6>Sept 3-5, 2021 @ <em>Portage County Fair, Portage County Fairgrounds, Rosholt Fair Park</em> <br />Rosholt, WI
                      <br />
                      <b>TBA</b>
                    </h6>
                    <Button color="primary" size="sm" style={{ position: 'absolute', right: 15, top: 230 }}
                      href="https://www.facebook.com/visiblesoundshow/events/?ref=page_internal" target="_blank" disabled>
                      TBA
                    </Button>
                  </div>
                </p>
              </span>
            </GridItem>
            <GridItem xs={0} md={2} />
          </GridContainer> */}
        </div>
      </div>
    </div>
  );
}
