import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import SectionVideoDemo from "./SectionVideoDemo.js";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles(styles);
export default function PictureGalleryDivider() {
  const classes = useStyles();
  return (
    <div className={classes.section} style={{ paddingBottom: 0, paddingRight: 15, paddingLeft: 15 }}>
      <GridContainer justify="center">
        <Hidden smDown>
          <GridItem xs={0} sm={6} md={4} style={{ paddingRight: 0, paddingLeft: 0 }}>
            <img
              src={require("assets/drive-media/Website-Gallery/20200627_204244.jpg")}
              alt="..."
              className={
                classes.imgRaised +
                " " +
                classes.imgFluid
              } />
          </GridItem>
        </Hidden>
        <GridItem sm={6} md={4} style={{ paddingRight: 0, paddingLeft: 0, marginTop: "1.5%" }}>
          <img
            src={require("assets/drive-media/Website-Gallery/20200627_205314.jpg")}
            alt="..."
            className={
              classes.imgRaised +
              " " +
              classes.imgFluid
            }
          />
          <img
            src={require("assets/drive-media/Website-Gallery/20200627_205332.jpg")}
            alt="..."
            className={
              classes.imgRaised +
              " " +
              classes.imgFluid
            }
          />
          <img
            src={require("assets/drive-media/Website-Gallery/20200627_205316.jpg")}
            alt="..."
            className={
              classes.imgRaised +
              " " +
              classes.imgFluid
            } />
        </GridItem>
        <Hidden smDown>
          <GridItem xs={0} sm={6} md={4} style={{ paddingRight: 0, paddingLeft: 0 }}>
            <img
              src={require("assets/drive-media/Website-Gallery/20200627_204053.jpg")}
              alt="..."
              className={
                classes.imgRaised +
                " " +
                classes.imgFluid
              } />
          </GridItem>
        </Hidden>
      </GridContainer>
    </div>
  );
}
