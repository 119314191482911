import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import SectionVideoDemo from "./SectionVideoDemo.js";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section} style={{paddingBottom: 0, paddingRight: 0}, isMobile ? {paddingTop: 20, paddingBottom: 0, marginTop: 0} : {paddingBottom: 0}}>
      <h2 className={classes.title}>About us</h2>
      <hr style={{width: '10%'}}/>
      <GridContainer justify="center" style={{marginLeft: "2%", marginRight: "2%"}}>
        <GridItem xs={12} sm={12} md={6}>
          <h5 className={classes.description}>
            Visible Sound is a duo performance act consisting of guitarist Sage Leary and juggler Mark Fiore 
            which fuses the engaging art forms of music and juggling into a cutting-edge live performance spectacle
            for all ages and entertainment preferences. Below are some original performances. More can be found on our 
            {" "}<a href="https://www.youtube.com/channel/UCyyxaQzhigyJvb4xR7z87bQ" target="_blank">Youtube</a>.
          </h5>
        </GridItem>
      </GridContainer>
      {/* <SectionVideoDemo /> */}
      
      {/* <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Free Chat"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Verified Users"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Fingerprint"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={Fingerprint}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div> */}
    </div>
  );
}
