import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  return (
    <div className={classes.section} style={{ paddingTop: 0, paddingBottom: 0, textAlign: "center"}}>
      {/* <GridContainer justify="center" style={{paddingLeft: 15, paddingRight: 15}}> */}
        {/* <GridItem cs={12} sm={12} md={6}> */}
        <Button color="rose" size="lg" style={{marginTop: 30}} href="mailto:visiblesoundshow@gmail.com?subject=Booking%20Request&body=%0D%0A**Please%20provide%20details%20such%20as%20your%20organization,%20type%20or%20length%20of%20performance%20desired,%20and%20event%20description.%20We%20will%20respond%20as%20soon%20as%20possible!">
          <i class="far fa-envelope" style={{fontSize: "40px", paddingRight: 20}}></i>
          <h2 className={classes.title} style={{marginTop: 10, marginBottom: 10, alignSelf: 'center', color: "white"}}>Book us!</h2>
        </Button>
        <br />
          {/* <h4 className={classes.description}>
            **Please provide details such as your organization, type or length of performance desired, and event description. 
            We will respond as soon as possible! :)
            **Please%20provide%20details%20such%20as%20your%20organization,%20type%20or%20length%20of%20performance%20desired,%20and%20event%20description.%20We%20will%20respond%20as%20soon%20as%20possible!
            
          </h4>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Name"
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <CustomInput
                labelText="Your Message"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                inputProps={{
                  multiline: true,
                  rows: 5
                }}
              />
              <GridItem xs={12} sm={12} md={4}>
                <Button color="primary">Send Message</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem> */}
      {/* </GridContainer> */}
    </div>
  );
}
