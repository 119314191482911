import React from "react";
// react component for creating beautiful carousel
import { Player } from 'video-react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import bookingnowvid from "assets/drive-media/booking-now-2021.mp4";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
const useStyles = makeStyles(styles);

export default function SectionVideoDemo() {
  const classes = useStyles();
  return (
    <div style={{
      marginLeft: isMobile ? "5vw" : "15vw",
      marginRight: isMobile ? "5vw" : "15vw",
      paddingTop: 15,
      paddingBottom: 30
    }}>
      <h2 className={classes.title} style={{textAlign: "center", fontSize: "1.5em"}}>"Voices" (Original)</h2>
      <div style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}>
        
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
          src="https://www.youtube.com/embed/t5Rvf7QXUPU?start=9"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <h2 className={classes.title} style={{textAlign: "center", fontSize: "1.5em", paddingTop: 50}}>"On and On" (Original)</h2>
      <div style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}>
        <iframe 
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
          src="https://www.youtube.com/embed/gPXV6UhCIWQ?start=9" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen>

        </iframe>
      </div>
    </div>

  );
}
