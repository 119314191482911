import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  var video = document.getElementById("myVideo");

  // Get the button
  var btn = document.getElementById("myBtn");

  // Pause and play the video, and change the button text
  function myFunction() {
    if (video.paused) {
      video.play();
      btn.innerHTML = "Pause";
    } else {
      video.pause();
      btn.innerHTML = "Play";
    }
  }
  return (
    <div>
    <video loop muted playsInline autoPlay src={require("assets/drive-media/promo.mp4")}/>
    {/* <video autoplay muted loop playsinline id="myVideo" className="video">
      <source src={require("assets/drive-media/promo.mp4")} type="video/mp4" />
    </video> */}
    {/* <div className="content">
      <h1 >Heading</h1>
      <p>Lorem ipsum...</p>
      <button className="myBtn" id="myBtn" onClick={myFunction}>Pause</button>
    </div> */}
    </div>

  );
  
}

