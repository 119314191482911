import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
// import SectionBasics from "./Sections/SectionBasics.js";
// import SectionNavbars from "./Sections/SectionNavbars.js";
// import SectionTabs from "./Sections/SectionTabs.js";
import SectionPills from "./Sections/SectionPills.js";
import SocialMediaButtons from "./Sections/SocialMediaButtons.js";
import AboutSection from "./Sections/AboutSection.js";
import BookingFormSection from "./Sections/BookingFormSection.js";
import SectionVideoDemo from "./Sections/SectionVideoDemo.js";
import PictureGalleryDivider from "./Sections/PictureGalleryDivider.js";

// import SectionNotifications from "./Sections/SectionNotifications.js";
// import SectionTypography from "./Sections/SectionTypography.js";
// import SectionJavascript from "./Sections/SectionJavascript.js";
import SectionCarousel from "./Sections/SectionCarousel.js";
// import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
// import SectionLogin from "./Sections/SectionLogin.js";
// import SectionExamples from "./Sections/SectionExamples.js";
// import SectionDownload from "./Sections/SectionDownload.js";
import WorkSection from "views/LandingPage/Sections/WorkSection";

import styles from "assets/jss/material-kit-react/views/components.js";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>

      <Header
        brand="Visible Sound"
        rightLinks={<HeaderLinks />}
        fixed
        color={"transparent"}
        changeColorOnScroll={{
          height: isMobile ? 175 : 400,
          color: "white"
        }}
        {...rest}
      />

      {/* <div className="content">
        <h1>Scroll Down</h1>
      </div> */}
      {isMobile ? (
        <div style={{
          position: "relative",
          paddingBottom: "56.25%" /* 16:9 */,
          paddingTop: 25,
          height: 0,
        }}>
          <img
              src={require("assets/drive-media/promopic.jpg")}
              alt="..."
              className={
                classes.imgRaised +
                " " +
                classes.imgFluid
              } 
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
            />
        </div>
            
      ) : (
    <Parallax image={require("assets/drive-media/promopic.jpg")} />

    //    <div className={classes.container}>
    //       <GridContainer>
    //         <GridItem>
    //           <div className={classes.brand}>
    //             <h1 className={classes.title}>Visible Sound</h1>
    //             <h3 className={classes.subtitle}>
    //             </h3>
    //           </div>
    //         </GridItem>
    //         </GridContainer>
    //     </div> */}
    //     VIDEO DOESN'T WORK WHEN HOSTING FOR SOME REASON...PIC STILL LOADS THO GOOD I GUESS */}
    //     <video autoPlay muted className="video">
    //       <source src={require("assets/drive-media/edited-booking-now-vid.mp4")} type="video/mp4" />
    //     </video>

      )}
      

      <div className={isMobile ? classNames(classes.main) : classNames(classes.main, classes.mainRaised)}>
        {/* header + small bio */}
        <AboutSection />

        {/* youtube embedded video  */}
        <SectionVideoDemo />

        {/* unused form (button for now) */}
        <BookingFormSection />

        {/* small picture gallery */}
        <PictureGalleryDivider />

        {/* schedule */}
        <SectionPills />

        {/* social media links */}
        <SocialMediaButtons />
        {/* unused references */}

        {/* <SectionBasics /> */}
        {/* <SectionNavbars /> */}
        {/* <SectionTabs /> */}
        {/* <SectionCarousel /> */}
        {/* <SectionNotifications /> */}
        {/* <SectionTypography />
        <SectionJavascript />
        <SectionCarousel />
        <SectionCompletedExamples />
        <SectionLogin />
        <GridItem md={12} className={classes.textCenter}>
          <Link to={"/login-page"} className={classes.link}>
            <Button color="primary" size="lg" simple>
              View Login Page
            </Button>
          </Link>
        </GridItem>
        <SectionExamples />
        <SectionDownload /> */}
      </div>
      <Footer />
    </div>


  );
}
