import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

import styles from "assets/jss/material-kit-react/views/landingPage.js";

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
      <div>
          nothing
      </div>
    // <video playsinline autoplay muted loop poster="promopic.mp4" id="bgvid">
    //   <source src="src\assets\drive-media\promo.mp4" type="video/mp4" />
    // </video>
  );
}