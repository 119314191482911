import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";

import styles from "assets/jss/material-kit-react/views/componentsSections/socialMediaFooterStyles.js";
import GridContainer from "components/Grid/GridContainer";

const useStyles = makeStyles(styles);

export default function SocialMediaButtons() {
  const classes = useStyles();
  return (
    <div className={classes.section} style={{paddingTop: 0, paddingBottom: 30, paddingTop: 30}}>
      <Grid container justify="center">
        <List className={classes.list} >
          <ListItem className={classes.listItem}>
            <Tooltip
              id="instagram-facebook"
              title="Follow us on facebook"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="transparent"
                href="https://www.facebook.com/visiblesoundshow/"
                target="_blank"
                className={classes.navLink}
              >
                <i className={classes.socialIcons + " fab fa-facebook"} />
              </Button>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Tooltip
              id="instagram-tooltip"
              title="Follow us on instagram"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="transparent"
                href="https://www.instagram.com/visiblesoundshow/"
                target="_blank"
                className={classes.navLink}
              >
                <i className={classes.socialIcons + " fab fa-instagram"} />
              </Button>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Tooltip
              id="youtube-tooltip"
              title="Subscribe to us on youtube"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="transparent"
                href="https://www.youtube.com/channel/UCyyxaQzhigyJvb4xR7z87bQ"
                target="_blank"
                className={classes.navLink}
              >
                <i class="fab fa-youtube"></i>
              </Button>
            </Tooltip>
          </ListItem>
        </List>
        </Grid>
    </div>
  );
}
