import { container } from "assets/jss/material-kit-react.js";

const carouselStyle = {
  // section: {
  //   padding: "70px 0"
  // },
  // container,
  // marginAuto: {
  //   marginLeft: "auto !important",
  //   marginRight: "auto !important"
  // },
  videoWrapper: {
    position: "relative",
    paddingBottom: "56.25%", //== 16:9 video ratio
    height: "0px"
  },
  iframe: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
  },
  // maxWidth: {
  //   maxWidth: 1300
  // }
};

export default carouselStyle;
